import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  toggleStatus=true;
  url:any;
  currentrout:any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
     
      this.url= this.activatedRoute.root;
      this.currentrout=this.url.snapshot._routerState.url;
      if(this.currentrout==='/'){
        this.toggleStatus=!this.toggleStatus;
        setTimeout(()=>{
          this.toggleStatus=false
        }, 100)
      }else{
        this.toggleStatus=false;
      }
  });

}


  ngOnInit(): void {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").setAttribute(
          "style", "top: 0; transition-delay: 2s;");
      } else {
        document.getElementById("navbar").setAttribute(
          "style", "top: -150px; transition-delay: 1s;");
      }
      prevScrollpos = currentScrollPos;
    }
  }
}
