import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publicwrapper',
  templateUrl: './publicwrapper.component.html',
  styleUrls: ['./publicwrapper.component.scss']
})
export class PublicwrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
