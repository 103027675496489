<footer class="footerwrap">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-12 mt-1">
        <div class="follow-us">
          <a href=""
            ><img
              src="../../../../assets/images/logonew.png"
              class="img-fluid"
              alt="footer logo"
            />
          </a>

          <div class="followicons">
            <p>Follow us on</p>
            <ul class="list-unstyled d-flex">
              <li>
                <a href=""><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href=""><i class="fa fa-instagram"></i></a>
              </li>
              <li>
                <a href=""><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href=""
                  ><i class="fa fa-youtube-play" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 mt-1">
        <div class="footermenu">
          <ul class="list-unstyled">
            <li>
              <a [routerLink]="['/Privacy-and-terms']">PRIVACY + TERMS</a>
            </li>
            <!--<li>
              <a href="">Lorem ipsum</a>
            </li>
            <li>
              <a href="">Lorem ipsum</a>
            </li>-->
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <p class="alertSucces" *ngIf="alert" >Your Email  has been submitted <i class="fa fa-check" aria-hidden="true"></i></p>
        <form [formGroup]="submitForm">
        <div class="search-bar mt-mb-5">
          <input
            type="text"
            class="hello-enteremail"
            formControlName="email"
            placeholder=" ENTER YOUR EMAIL TO RECEIVE UPDATES FROM THE MANAGEMENT"
          />
          <input
            class="hello-enter"
            type="text"
            formControlName="email"
            placeholder=" ENTER YOUR EMAIL TO RECEIVE UPDATES "
          />
          <button class="btn" (click)="sendData()">SUBMIT</button>
        </div>
        </form>
        <div class="follow-us managment">
          <p>
            By submitting your email, you are consenting to receive update
            emails from THE CROSSK Management, 20th Floor, Central Tower, 28
            Queen’s Road Central, Hong Kong, Asia
            <a href="">http://www.crossk.ventures </a>.You can revoke your
            consent to receive emails at any time by using the Safe Unsubscribe®
            link, found at the bottom of every email. Please read our privacy
            statement to understand how we plan to use your personal
            information.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="copywrite-wrap">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-7">
          <p class="text-center mb-2">
            COPYWRIGHT
            <i class="fa fa-copyright" aria-hidden="true"></i>
            2021, THE CROSSK. ALL RIGHTS RESERVED
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
