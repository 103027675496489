import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BannerComponent } from './components/banner/banner.component';
import { PublicwrapperComponent } from './shared/layouts/publicwrapper/publicwrapper.component';
import { RouteConstant } from '../app/constants/routeconst'
const routes: Routes = [
  {
    path: '',
    component: PublicwrapperComponent,
    children: [

      {

        path: '',
        loadChildren: () => import('../app/modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('../app/modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'aboutus',
        loadChildren: () => import('../app/modules/aboutus/aboutus.module').then(m => m.AboutusModule)
      },

      {
        path: 'Investors-FAQ',
        loadChildren: () => import('../app/modules/home-flip/home-flip.module').then(m => m.HomeFlipModule)
      },
      {
        path: 'groundwork',
        loadChildren: () => import('../app/modules/home-page-replica/home-page-replica.module').then(m => m.HomePageReplicaModule)
      },
      {
        path: 'Privacy-and-terms',
        loadChildren: () => import('../app/modules/termandcodition/termandcodition.module').then(m => m.TermandcoditionModule)

      },
      {
        path: 'term-and-condition-introduction',
        loadChildren: () => import('../app/modules/termandcondition-introducion/termandcondition-introducion.module').then(m => m.TermandconditionIntroducionModule)

      },
      {
        path: 'howweuse',
        loadChildren: () => import('../app/modules/how-we-use-data/how-we-use-data.module').then(m => m.HowWeUseDataModule)

      },
      {
        path: 'howewsahre',
        loadChildren: () => import('../app/modules/how-we-share-info-ts/how-we-share-info-ts.module').then(m => m.HowWeShareInfoTsModule)

      },
      {
        path: 'otherinformation',
        loadChildren: () => import('../app/modules/otherinformation/otherinformation.module').then(m => m.OtherinformationModule)

      },
      {
        path: 'yourchoice',
        loadChildren: () => import('../app/modules/your-choices/your-choices.module').then(m => m.YourChoicesModule)

      },
      {
        path: 'data-collect',
        loadChildren: () => import('../app/modules/t-c-data-collect/t-c-data-collect.module').then(m => m.TCDataCollectModule)

      },
      {
        path: 'businesses',
        loadChildren: () => import('../app/modules/businesses/businesses.module').then(m => m.BusinessesModule)
      },
      {
        path: 'ourculture',
        loadChildren: () => import('../app/modules/ourculture/ourculture.module').then(m => m.OurcultureModule)
      },
      {
        path: 'ourpartnerships',
        loadChildren: () => import('../app/modules/ourpartnerships/ourpartnerships.module').then(m => m.OurpartnershipsModule)
      },
      {
        path: 'media',
        loadChildren: () => import('../app/modules/media/media.module').then(m => m.MediaModule)
      },
      {
        path: 'progressdesign',
        loadChildren: () => import('../app/modules/progressdesign/progressdesign.module').then(m => m.ProgressdesignModule)
      },
      {
        path: 'event-replica',
        loadChildren: () => import('../app/modules/pagination-replica-page/pagination-replica-page.module').then(m => m.PaginationReplicaPageModule)
      },
      {
        path: RouteConstant.Banner,
        component: BannerComponent
      },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
  
  // useHash: true,
  // preloadingStrategy:PreloadAllModules,
    anchorScrolling: 'enabled',
  // scrollPositionRestoration: "enabled",
        
  }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
