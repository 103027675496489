<section class="header-one" id="navbar">
  <div class="blackbg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-8 col-sm-6 col-md-6">
          <div
            class="logo"
            data-aos="fade-down"
            data-aos-duration="1000"
            (click)="toggleStatus = !toggleStatus"
          >
            <img
              [routerLink]="''"
              src="../../../../assets/images/logonew.png"
              class="img-fluid"
              alt="Logo"
            />
          </div>
        </div>
        <div class="col-4 col-sm-6 col-md-6 d-flex justify-content-end">
          <div
            class="button_container"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <mat-icon
              (click)="toggleStatus = !toggleStatus"
              style="font-size: 48px; width: 57px"
            >
              {{ toggleStatus === true ? "close" : "menu" }}
            </mat-icon>
          </div>

          <div *ngIf="toggleStatus === true">
            <div class="overlay toggleStatus visible">
              <div class="container">
                <div class="row justify-content-center mt-4">
                  <div
                    class="col-sm-6 col-md-6"
                    alt=""
                    data-aos="zoom-in-up"
                    data-aos-duration="1000"
                  >
                    <nav class="overlay-menu">
                      <ul class="list-unstyled">
                        <li>
                          <a class="Explore-nav text-left">EXPLORE</a>
                        </li>
                        <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/']"
                            class="Explore mt-5"
                            >CROSSK</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/aboutus']"
                            class="Explore"
                            >ABOUT US</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/businesses/businesses']"
                            class="Explore"
                            >BUSINESSES</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/ourculture']"
                            class="Explore"
                            >OUR CULTURE</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/ourpartnerships']"
                            class="Explore"
                            >OUR PARTNERSHIPS</a
                          >
                        </li>
                        <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/media']"
                            class="Explore"
                            >MEDIA</a
                          >
                        </li>
                        <!-- <li>
                          <a
                            (click)="toggleStatus = false"
                            [routerLink]="['/term-and-condition']"
                            class="Explore"
                            >SECURITY AND PRIVACY</a
                          >
                        </li> -->
                      </ul>
                    </nav>
                  </div>
                  <div
                    class="col-sm-6 col-md-6 mt-4 mt-sm-0"
                    alt=""
                    data-aos="zoom-in-up"
                    data-aos-duration="1000"
                  >
                    <div class="row no-gutters">
                      <div class="col-md-6">
                        <nav class="overlay-menu">
                          <ul class="list-unstyled">
                            <li><a href="#" class="Explore-nav">LEARN</a></li>
                            <li>
                              <a
                                (click)="toggleStatus = false"
                                [routerLink]="['/contact']"
                                class="explore-new-line"
                                >CONTACT</a
                              >
                            </li>
                            <li>
                              <a
                                (click)="toggleStatus = false"
                                [routerLink]="['/Privacy-and-terms']"
                                class="explore-new-line"
                                >PRIVACY AND TERMS</a
                              >
                            </li>
                            <!-- <li>
                              <a
                                (click)="toggleStatus = false"
                                href="#"
                                class="explore-new-line"
                                >GOOGLE</a
                              >
                            </li> -->
                          </ul>
                        </nav>
                      </div>
                      <div class="col-md-6 mt-4 mt-md-0">
                        <nav class="overlay-menu">
                          <ul class="list-unstyled">
                            <li><a href="#" class="Explore-nav">CONNECT</a></li>
                            <li>
                              <a
                                href="#"
                                (click)="toggleStatus = false"
                                class="explore-new-line"
                              >
                                <span>
                                  <i class="fa fa-linkedin"></i>
                                </span>
                                <span>LINKEDIN</span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                (click)="toggleStatus = false"
                                class="explore-new-line"
                              >
                                <span> <i class="fa fa-twitter"></i></span>
                                <span>TWITTER</span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                (click)="toggleStatus = false"
                                class="explore-new-line"
                              >
                                <span>
                                  <i class="fa fa-instagram"></i>
                                </span>
                                <span>INSTAGRAM</span></a
                              >
                            </li>
                            <li>
                              <a
                                href="#"
                                (click)="toggleStatus = false"
                                class="explore-new-line"
                              >
                                <span
                                  ><i
                                    class="fa fa-youtube-play"
                                    aria-hidden="true"
                                  ></i
                                ></span>
                                <span>YOUTUBE</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
