import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment as ENV } from '../../../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  alert: boolean = false;
  ipAddress: string;
  lat: number;
  lng: number;
  email: string;
  submitForm: FormGroup;
  constructor(private http: HttpClient, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
    this.getIP();
    this.getLocation();
  }

  createForm() {
    let emailregex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    this.submitForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(emailregex)]],
      ipAddress: [""],
      lat: [""],
      lng: [""]
    })
  }

  getIP() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  getLocation() {
    var _this = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          _this.lat = position.coords.latitude;
          _this.lng = position.coords.longitude;
        }
      })
    }
  }

  sendData() {
    if (this.submitForm.valid) {
      this.submitForm.patchValue({ ipAddress: this.ipAddress, lat: String(this.lat), lng: String(this.lng) })
      this.http.post(ENV.apiUrl + "addEmail", this.submitForm.value).subscribe((res: any) => {
        if (res.details[0].messages) {
          this.submitForm.patchValue({ email: '' })
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 3000);
        }
      });

    }

  }
}
